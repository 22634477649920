import React from "react"
import { StaticQuery, graphql } from "gatsby"
import * as am5 from "@amcharts/amcharts5/"
import * as am5percent from "@amcharts/amcharts5/percent"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import * as styles from "../../../styles/components/chart.module.scss"
import nl2br from "react-nl2br"
import canvasMemoryReset from "../../../utils/canvas-memory-reset"

// markup
class PieBasic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData
    }
    
    this.series = [];
    this.filePath = "/csv/pie-basic/" // CSVデータ格納先
  }

  // config setter
  setConfig(data) {
    this.config = data
  }

  // config getter
  getConfig() {
    return this.config
  }

  // チャートの設定
  async setChartData() {
    am5.addLicense(process.env.AM_CHARTS_LICENSE)
    
    const chartConfig = this.getConfig()

    let root = am5.Root.new(this.props.id)
    root.setThemes([
      am5themes_Animated.new(root)
    ])
    this.root = root

    // 基本設定
    let chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
      innerRadius: am5.percent(40)
    }));
    
    // CSVファイルからデータの読み込み
    let dataSource = `${this.filePath}${this.state.chartData}.csv`
    let data = await am5.net.load(dataSource).then(function(result) {
      // CSVパースオプション
      let data = am5.CSVParser.parse(result.response, {
        delimiter: ",",
        reverse: false,
        skipEmpty: true,
        useColumnNames: true
      });
      // 型変換処理
      let processor = am5.DataProcessor.new(root, {
        numericFields: ['value']
      });
      processor.processMany(data);

      return data
    })

    // ラベル
    chart.seriesContainer.children.push(
      am5.Label.new(root, {
        textAlign: "center",
        centerY: am5.p50,
        centerX: am5.p50,
        text: chartConfig.total[this.state.chartData]
      })
    );

    // シリーズデータ作成
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        endAngle: 270,
        alignLabels: false
      })
    );

    // テンプレート指定
    series.labels.template.setAll({
      fontSize: "11px",
      inside: true,
      text: "{category} {valuePercentTotal.formatNumber('0.0')}%"
    })

    // 色指定
    series.get("colors").set("colors", chartConfig.series[this.state.chartData].map(item => {
      return item.fill
    }))
    
    series.states.create("hidden", {
      endAngle: -90
    });

    series.slices.template.set("tooltipText", chartConfig.tooltip[this.state.chartData])

    series.data.setAll(data)

    this.chart = chart
  }

  componentDidMount() {
    this.setChartData()
  }

  componentDidUpdate(prevState) {
    if (prevState.dataSourceUrl !== this.state.dataSourceUrl ) {
      this.root.dispose()
      this.setChartData()
    }
  }

  componentWillUnmount() {
    canvasMemoryReset(`#${this.props.id} canvas`)
    if (this.root) {
      this.root.dispose()
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query allPieBasicJsonQuery {
            settings: allPieBasicJson {
              edges {
                node {
                  series {
                    wastedTiresRecyclingEu {
                      fill
                      label
                      name
                    }
                  }
                  title {
                    wastedTiresRecyclingEu
                  }
                  tooltip {
                    wastedTiresRecyclingEu
                  }
                  download {
                    wastedTiresRecyclingEu
                  }
                  total {
                    wastedTiresRecyclingEu
                  }
                  source {
                    wastedTiresRecyclingEu {
                      explain
                      sourceDate
                      sourceLink
                      sourceText
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            { this.setConfig(data.settings.edges[0].node) }
            <div className={styles.chartTitle}>
              <h2>{data.settings.edges[0].node.title[this.state.chartData]}</h2>
            </div>
            <div id={this.props.id} style={{ width: this.props.width, height: this.props.height }}></div>
            <div className={styles.chartNote}>
              <p className={styles.chartSource}>出所）<a href={data.settings.edges[0].node.source[this.state.chartData].sourceLink} target="_blank" rel="noreferrer noopener">{data.settings.edges[0].node.source[this.state.chartData].sourceText}</a>
                {data.settings.edges[0].node.source[this.state.chartData].sourceDate}</p>
              <p className={styles.chartExplain}>{nl2br(data.settings.edges[0].node.source[this.state.chartData].explain)}</p>
              { (data.settings.edges[0].node.download[this.state.chartData]) ? 
                <p><a className={styles.chartDataDownload} href={`${this.filePath}${this.state.chartData}.csv`}>データダウンロード</a></p>
                : ''
              }
            </div>
          </>
        )}
      />
    )
  }
}
export default PieBasic
