import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"
import specialCharacterReplace from "../../utils/special-character-replace"

import SearchFilter from "../../components/parts/chart/search-filter"
import NestedDonut from "../../components/parts/chart/nested-donut"
import PieBasic from "../../components/parts/chart/pie-basic"
import ImageBox from "../../components/parts/image-box"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      s07_1_1_1 : false,
      s07_1_1_2 : false,
      s07_1_1_4 : false,
      s07_1_1_5 : false,
      s07_1_1_7 : false
    }

    this.searchInit = {
      filter1Init: 4,
      filter2Init: 1,
      filter3Init: 'market-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
        <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>エチレン系誘導品の国・地域別需給バランスの推移に関する見通し（エチレン換算）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s07_1_1_1"}>
                <img src={'../../images/chart/s07-1-1-1.png'} alt="エチレン系誘導品の国・地域別需給バランスの推移に関する見通し（エチレン換算）" width={559} />
              </button>
              <ImageBox
                open={this.state.s07_1_1_1}
                close={this.closeModal}
                name={"s07_1_1_1"}
                src={'/images/chart/s07-1-1-1.png'}
                alt="エチレン系誘導品の国・地域別需給バランスの推移に関する見通し（エチレン換算）"
                width={1153}
                height={639} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.meti.go.jp/policy/mono_info_service/mono/chemistry/downloadfiles/01_2019souronr.pdf" target="_blank" rel="noreferrer noopener">経済産業省「世界の石油化学製品の今後の需給動向」</a>（2019年10月）</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>プロピレン系誘導品の国・地域別需給バランスの推移に関する見通し （プロピレン換算）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s07_1_1_2"}>
                <img src={'../../images/chart/s07-1-1-2.png'} alt="プロピレン系誘導品の国・地域別需給バランスの推移に関する見通し （プロピレン換算）" width={559} />
              </button>
              <ImageBox
                open={this.state.s07_1_1_2}
                close={this.closeModal}
                name={"s07_1_1_2"}
                src={'/images/chart/s07-1-1-2.png'}
                alt="プロピレン系誘導品の国・地域別需給バランスの推移に関する見通し （プロピレン換算）"
                width={1095}
                height={651} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.meti.go.jp/policy/mono_info_service/mono/chemistry/downloadfiles/01_2019souronr.pdf" target="_blank" rel="noreferrer noopener">経済産業省「世界の石油化学製品の今後の需給動向」</a>（2019年10月）</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <NestedDonut id="wastedTiresRecycling" chartData="wastedTiresRecycling" width="100%" height="420px" />
            </div>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>廃タイヤのリサイクル状況（米国、発生量ベース、2019年）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s07_1_1_4"}>
                <img src={'../../images/chart/s07-1-1-4.png'} alt="廃タイヤのリサイクル状況（米国、発生量ベース、2019年）" width={559} />
              </button>
              <ImageBox
                open={this.state.s07_1_1_4}
                close={this.closeModal}
                name={"s07_1_1_4"}
                src={'/images/chart/s07-1-1-4.png'}
                alt="廃タイヤのリサイクル状況（米国、発生量ベース、2019年）"
                width={1184}
                height={571} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.ustires.org/sites/default/files/2019%20USTMA%20Scrap%20Tire%20Management%20Summary%20Report.pdf" target="_blank" rel="noreferrer noopener">USTMA（米国タイヤ製造者協会）、"2019 U.S. Scrap Tire Management Summary"</a>（2020年）</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>廃タイヤのリサイクル状況（米国、市場取引ベース）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s07_1_1_5"}>
                <img src={'../../images/chart/s07-1-1-5.png'} alt="廃タイヤのリサイクル状況（米国、市場取引ベース）" width={559} />
              </button>
              <ImageBox
                open={this.state.s07_1_1_5}
                close={this.closeModal}
                name={"s07_1_1_5"}
                src={'/images/chart/s07-1-1-5.png'}
                alt="廃タイヤのリサイクル状況（米国、市場取引ベース）"
                width={1053}
                height={567} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.ustires.org/sites/default/files/2019%20USTMA%20Scrap%20Tire%20Management%20Summary%20Report.pdf" target="_blank" rel="noreferrer noopener">USTMA（米国タイヤ製造者協会）、"2019 U.S. Scrap Tire Management Summary"</a>（2020年）</p>
              </div>
            </div>
            <div className={dashboardStyles.chartAreaItem}>
              <PieBasic id="wastedTiresRecyclingEu" chartData="wastedTiresRecyclingEu" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>用途別メタノール需要（世界）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s07_1_1_7"}>
                <img src={'../../images/chart/s07-1-1-7.png'} alt="用途別メタノール需要（世界）" width={559} />
              </button>
              <ImageBox
                open={this.state.s07_1_1_7}
                close={this.closeModal}
                name={"s07_1_1_7"}
                src={'/images/chart/s07-1-1-7.png'}
                alt="用途別メタノール需要（世界）"
                width={1001}
                height={650} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.methanol.org/wp-content/uploads/2020/04/IRENA_Innovation_Renewable_Methanol_2021.pdf" target="_blank" rel="noreferrer noopener">IRENA AND METHANOL INSTITUTE, "Innovation Outlook : Renewable Methanol"</a>（2021年1月）</p>
              </div>
            </div>
            <div className={dashboardStyles.chartAreaItem}>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`